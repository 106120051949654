<template>
    <section class="brand-main-page-section top-sellings-section">
        <div class="page-section brand-top-selling">
            <div class="brand-top-selling-header">
                <div class="section-title-wrapper">
                    <Skeletor class="section-title" width="30%" />
                    <Skeletor class="dosiin_slider-controls" width="20%"/>
                </div>
                <div class="dosiin_brand-vertical-ranking swiper-filter-container dosiin_mt-16">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <ol class="brand-top-selling_list">
                                <li class="brand-top-selling-item_wrapper brand-item dosiin_brand-follow-wrapper" v-for="i in 5" :key="i">
                                    <BrandItemHorizon />
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="seemore-btn">        
                <a class="">            
                    <Skeletor width="100%" height="25" pill /> 
                </a>    
            </div>
        </div>
    </section>
</template>

<script>
    import BrandItemHorizon from '@/components/skeletons/brand/BrandItemHorizon';
    export default {
        components:{
            BrandItemHorizon
        },
        setup() {
            
        },
    }
</script>