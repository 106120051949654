<template>
    <div class="page-section">
        <div class="section-title-wrapper">
            <Skeletor class="section-title" width="30%" />
            <Skeletor class="dosiin_slider-controls" width="20%"/>
        </div>
        <div class="grid grid-33">
            <div class="grid-item" v-for="i in 3" :key="i">
                <div class="ranking-brand-card">
                    <div class="ranking-brand-card-top d-flex align-items-center dosiin_text-center">
                        <div class="ranking-brand-card-top-left">
                            <div class="ranking-top-nummber">
                                <span>{{ i }}</span>
                                <div class="ranking-top-icon">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6665 11.3334H1.33317L1.94796 10.4091L7.99984 1.16671L14.0517 10.4091L14.6665 11.3334Z" fill="#25BDFE"></path>
                                    </svg>
                                </div>
                            </div>
                            <div class="ranking-top-view">
                                <div class="ranking-top-content-sub">
                                    <Skeletor width="80%" /> 
                                </div>
                                <div class="ranking-top-content-main">
                                    <Skeletor width="100%" /> 
                                </div>
                            </div>
                            <div class="ranking-top-review">
                                <div class="ranking-top-content-sub">
                                    <Skeletor width="80%" /> 
                                </div>
                                <div class="ranking-top-content-main">
                                    <Skeletor width="100%" /> 
                                </div>
                            </div>
                        </div>
                        <div class="ranking-brand-card-top-right">
                            <a title="dosiin">
                                <Skeletor width="76" height="76" style="margin:auto" circle /> 
                                <h5 class="brand-item_title truncate">
                                    <Skeletor width="100%" /> 
                                </h5>
                            </a>
                            <div class="ranking-brand-content d-flex align-items-center justify-content-center">
                                <div class="ranking-brand-item">
                                    <div class="ranking-brand-item-total"><Skeletor width="60%" /> </div>
                                    <span><Skeletor width="30%" /></span>
                                </div>
                            </div>
                            <Skeletor width="135" heigth="36" pill />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-33">
            <div class="grid-item brand-top-selling_list" v-for="j in 6" :key="j">
                <div class="brand-top-selling-item_wrapper brand-item">
                    <div class="brand-ranking_wrapper">
                        <div class="brand-rank_text">{{ j }}</div>
                    </div>
                    <div class="brand-description_wrapper">
                        <div class="brand-description_img brand-item_img-wrapper">
                            <Skeletor width="76" height="76" circle />   
                        </div>
                        <div class="brand-description_info">
                            <a>
                                <h5 class="brand-item_title"><Skeletor width="100%" />  </h5>
                            </a>
                            <dl class="brand-item_social">
                                <dd><Skeletor width="60%" /></dd>
                                <dd><Skeletor width="60%" /></dd>
                            </dl>
                        </div>
                        <div class="brand-description_follow">
                            <Skeletor width="90" height="30" pill />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>