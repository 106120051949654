<template>
  <BrandRankingVerticalSkeleton v-if="brands.length == 0" />
  <section v-else class="brand-main-page-section top-sellings-section">
    <div class="page-section brand-top-selling">
      <div class="brand-top-selling-header">
        <div class="section-title-wrapper" v-if="bestSelling != true">
          <h1 class="brand-main-page-section_title">
            {{ title }}
          </h1>
          <div class="dosiin_slider-controls">
            <div
              class="dosiin_slider-pagination dosiin_slider-pagination-brand-vertical-with-ranking swipable-slider_pagination swiper-button-pagination swiper-filter-nav-bar"
            ></div>
          </div>
        </div>
        <div class="dosiin_brand-vertical-ranking swiper-filter-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <ol class="brand-top-selling_list">
                <template v-for="(brand, i) in brands" :key="i">
                  <li
                    class="brand-top-selling-item_wrapper brand-item dosiin_brand-follow-wrapper"
                  >
                    <div class="brand-ranking_wrapper">
                      <div class="brand-rank_text">{{ i + 1 }}</div>
                      <div class="brand-rank_change-icon"></div>
                    </div>
                    <div class="brand-description_wrapper">
                      <div class="brand-description_img brand-item_img-wrapper">
                        <BrandLogo :brand="brand" :width="62" />
                      </div>
                      <div class="brand-description_info">
                        <router-link
                          :to="_brandUrl(brand.seo_name)"
                          :title="brand.company"
                        >
                          <h5 class="brand-item_title">{{ brand.company }}</h5>
                        </router-link>
                        <dl class="brand-item_social">
                          <dd>
                            <span class="dosiin_brand-follow-number">{{
                              numberFormat(brand.follow_count)
                            }}</span>
                            &nbsp;người theo dõi
                          </dd>
                          <dd>{{ numberFormat(brand.products_count) }} sản phẩm</dd>
                          <dd>{{ numberFormat(brand.like_count) }} người thích trang</dd>
                        </dl>
                      </div>
                      <div class="brand-description_follow">
                        <FollowButton
                          class="brand_follow-btn primary-button follow-with-text-btn"
                          @update:modelValue="
                            (newValue) => (brand.follow_count = newValue)
                          "
                          followable_type="brand"
                          :followable_id="brand.company_id"
                          :is_follow="brand.is_follow"
                        />
                      </div>
                    </div>
                  </li>
                </template>
              </ol>
            </div>
          </div>
        </div>

        <div class="seemore-btn" v-if="link.length > 1 && bestSelling != true">
          <router-link
            :to="{ name: 'brands-bestselling' }"
            class="primary-button button"
            title="Top thương hiệu"
          >
            <span class="primary-link_text">Xem thêm</span>
            <i class="dsi dsi-act-arrow-forward"></i>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BrandRankingVerticalSkeleton from "@/components/skeletons/sections/BrandVerticalRanking";
import { toRefs } from "@vue/reactivity";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    brands: {
      type: Array,
      default: [],
    },
    link: {
      type: String,
      default: "/",
    },
    bestSelling: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BrandRankingVerticalSkeleton,
  },
  setup(props) {
    return {
      ...toRefs(props),
    };
  },
};
</script>
