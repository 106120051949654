<template>
    <div class="brand-ranking_wrapper">
        <div class="brand-rank_text">
            <Skeletor width="10" height="10" circle />
        </div>
        <div class="brand-rank_change-icon"></div>
    </div>
    <div class="brand-description_wrapper">
        <div class="brand-description_img brand-item_img-wrapper">
            <a>
                <Skeletor width="62" height="62" circle />
            </a>
        </div>
        <div class="brand-description_info">
            <a> 
                <h5 class="brand-item_title">
                    <Skeletor width="100%" />
                </h5>
            </a>
            <dl class="brand-item_social">
                <dd><Skeletor width="100%" /></dd>
                <dd><Skeletor width="50%" /></dd>
            </dl>
        </div>
        <div class="brand-description_follow">
            <button class="brand_follow-btn dosiin_brand_follow-btn primary-button follow-with-text-btn">
                <Skeletor width="90" height="21" pill/>
            </button>
        </div>
    </div>
</template>