<template>
  <BrandRankingHorizon v-if="brands.length === 0" />
  <div v-else class="page-section">
    <div class="section-title-wrapper">
      <h2 class="section-title section-title-product">Top thương hiệu</h2>
      <div class="product-see-all">
        <router-link
          :to="{ name: 'brands-bestselling' }"
          class="primary-button"
          title="Xem thêm sản phẩm"
        >
          <span class="primary-link_text">Xem tất cả</span>
          <i class="dsi dsi-act-arrow-forward"></i>
        </router-link>
      </div>
    </div>
    <div class="grid grid-33">
      <div class="grid-item" v-for="(brand, i) in brands.slice(0, 3)" :key="i">
        <div class="ranking-brand-card">
          <div
            class="ranking-brand-card-top d-flex align-items-center dosiin_text-center"
          >
            <div class="ranking-brand-card-top-left">
              <div class="ranking-top-nummber">
                <span>{{ i + 1 }}</span>
                <div class="ranking-top-icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.6665 11.3334H1.33317L1.94796 10.4091L7.99984 1.16671L14.0517 10.4091L14.6665 11.3334Z"
                      fill="#25BDFE"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="ranking-top-view">
                <div class="ranking-top-content-sub">Lượt thích</div>
                <div class="ranking-top-content-main">
                  {{ numberFormat(brand.like_count) }}
                </div>
              </div>
              <div class="ranking-top-product">
                <div class="ranking-top-content-sub">Sản phẩm</div>
                <div class="ranking-top-content-main">
                  {{ numberFormat(brand.products_count) }}
                </div>
              </div>
              <div class="ranking-top-review">
                <div class="ranking-top-content-sub">Đánh giá</div>
                <div class="ranking-top-content-main">
                  <div class="d-flex align-items-center justify-content-center">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.9161 9.72832C21.8205 9.43068 21.6446 9.16538 21.408 8.96181C21.1714 8.75823 20.8833 8.62431 20.5755 8.57488L15.7459 7.78742L13.5071 3.42254C13.3645 3.14466 13.1484 2.91157 12.8824 2.74883C12.6164 2.58609 12.3109 2.5 11.9994 2.5C11.6879 2.5 11.3824 2.58609 11.1164 2.74883C10.8504 2.91157 10.6343 3.14466 10.4917 3.42254L8.25286 7.78742L3.4234 8.57488C3.11593 8.62509 2.82818 8.7593 2.59174 8.96278C2.35529 9.16627 2.17927 9.43116 2.08301 9.72841C1.98674 10.0257 1.97394 10.3438 2.04601 10.6478C2.11808 10.9519 2.27224 11.2302 2.49157 11.4521L5.9374 14.9372L5.19157 19.7887C5.14371 20.0977 5.18172 20.414 5.30142 20.7027C5.42112 20.9914 5.61787 21.2415 5.87003 21.4253C6.1222 21.6091 6.42 21.7196 6.73069 21.7446C7.04139 21.7696 7.35293 21.7081 7.63107 21.5669L11.9994 19.3559L16.3679 21.5669C16.646 21.7078 16.9575 21.769 17.2681 21.7439C17.5786 21.7188 17.8763 21.6083 18.1284 21.4246C18.3804 21.2408 18.5772 20.9909 18.697 20.7023C18.8167 20.4138 18.8549 20.0976 18.8074 19.7887L18.0615 14.9372L21.5074 11.4521C21.7274 11.2306 21.882 10.9523 21.9541 10.6481C22.0263 10.3438 22.0131 10.0255 21.9161 9.72832Z"
                        fill="#FFCC07"
                      ></path>
                    </svg>
                    <span class="dosiin_ml-2"
                      >{{ parseInt(brand.discussion.average_rating) }}/5</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="ranking-brand-card-top-right">
              <router-link :to="_brandUrl(brand.seo_name)" :title="brand.company">
                <BrandLogo :brand="brand" :width="76" :height="76" />
                <h5 class="brand-item_title truncate">{{ brand.company }}</h5>
              </router-link>
              <div
                class="ranking-brand-content d-flex align-items-center justify-content-center"
              >
                <div class="ranking-brand-item">
                  <div class="ranking-brand-item-total">
                    {{ numberFormat(brand.follow_count) }}
                  </div>
                  <span>&nbsp; Người theo dõi</span>
                </div>
              </div>
              <FollowButton
                @update:modelValue="(newValue) => (brand.follow_count = newValue)"
                class="brand_follow-btn dosiin_brand_follow-btn primary-button button follow-with-text-btn"
                followable_type="brand"
                :followable_id="brand.company_id"
                :is_follow="brand.is_follow"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-33">
      <div
        class="grid-item brand-top-selling_list"
        v-for="(brand, i) in brands.slice(3, slideTo)"
        :key="i"
      >
        <div class="brand-top-selling-item_wrapper brand-item">
          <div class="brand-ranking_wrapper">
            <div class="brand-rank_text">{{ i + 4 }}</div>
          </div>
          <div class="brand-description_wrapper">
            <div class="brand-description_img brand-item_img-wrapper">
              <BrandLogo :brand="brand" :width="76" :height="76" />
            </div>
            <div class="brand-description_info">
              <router-link :to="_brandUrl(brand.seo_name)" :title="brand.company">
                <h5 class="brand-item_title">{{ brand.company }}</h5>
              </router-link>
              <dl class="brand-item_social">
                <dd>
                  <b>{{ numberFormat(brand.follow_count) }} </b
                  ><span>&nbsp;người theo dõi</span>
                </dd>
                <dd>
                  <b>{{ numberFormat(brand.products_count) }} </b
                  ><span>&nbsp;sản phẩm</span>
                </dd>
              </dl>
            </div>
            <div class="brand-description_follow">
              <FollowButton
                @update:modelValue="(newValue) => (brand.follow_count = newValue)"
                class="brand_follow-btn dosiin_brand_follow-btn primary-button button follow-with-text-btn"
                followable_type="brand"
                :followable_id="brand.company_id"
                :is_follow="brand.is_follow"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrandRankingHorizon from "@/components/skeletons/sections/BrandHorizonRanking";
import { toRefs } from "@vue/reactivity";

export default {
  props: {
    brands: {
      type: Array,
      default: [],
    },
    slideTo: {
      type: [String, Number],
      default: 9,
    },
  },
  components: {
    BrandRankingHorizon,
  },
  setup(props) {
    return {
      ...toRefs(props),
    };
  },
};
</script>
